<template>
  <div class="kingdee-audit" v-loading.fullscreen="loading">
    <el-card class="title-icon">
      <div>
        <img src="@/assets/images/kingdee-icon.png" alt="">
      </div>
    </el-card>
    <div v-if="status === 0" class="empty-data">
      <img src="@/assets/images/kingdee-empty-data.png" alt="">
      <div class="fs-16 color-909399">
        账套信息获取中，请稍后再试
      </div>
    </div>
    <div v-else class="kingdee-audit_content">
      <el-card class="box-card">
        <div class="text-center">
          <img
            src="@/assets/images/kingdee-process-two.png"
            style="width:450px"
            alt=""
          >
        </div>
      </el-card>

      <el-card class="mt-10">
        <div class="d-flex align-items-center">
          <div class="tag"/>
          <div class="fs-18 fw-600 color-303133 ml-14">
            绑定客户与帐套
          </div>
          <div class="ml-10">
            <i class="el-icon-warning color-F56C6C"/> 请核实帐套信息，准确填写易理货客户名称及88码，以便正常绑定。
          </div>
        </div>
        <div class="d-flex mt-20">
          <div class="f-300">
            <div>帐套</div>
            <div><el-input v-model="formData.accountName" placeholder="请输入"/></div>
          </div>
          <div class="f-300  ml-20 mr-20">
            <div><span class="color-F56C6C">*</span>易理货客户88码</div>
            <div>
              <el-input
                placeholder="请输入"
                v-number-input
                v-model="formData.sellerCode"
                @blur="getMemberName"
              />
            </div>
          </div>
          <div class="f-300">
            <div><span class="color-F56C6C">*</span>易理货客户名称</div>
            <div>
              <el-input
                disabled
                placeholder="请输入"
                v-model="formData.memberName"
              />
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="mt-10">
        <div class="d-flex align-items-center">
          <div class="tag"/>
          <div class="fs-18 fw-600 color-303133 ml-14">
            申请基本信息
          </div>
        </div>
        <div class="d-flex mt-20">
          <div class="f-300">
            <div><span class="color-F56C6C">*</span>您的姓名</div>
            <div><el-input placeholder="请输入" v-model="formData.applyName"/></div>
          </div>
          <div class="f-300 ml-25 mr-25">
            <div><span class="color-F56C6C">*</span>您的手机号</div>
            <div>
              <el-input
                placeholder="请输入"
                v-number-input="{ integer: 11 }"
                v-model="formData.applyPhoneNo"
              />
            </div>
          </div>
          <div class="f-300 mr-25">
            <div>年度销售规模(万元)</div>
            <div>
              <el-input
                placeholder="请输入"
                v-number-input
                v-model="formData.yearSalesScale"
              />
            </div>
          </div>
          <div class="f-300">
            <div><span class="color-F56C6C">*</span>是否有专职财务人员</div>
            <div class="d-flex align-items-center">
              <el-radio-group v-model="formData.financialFlag" class="flex-140">
                <el-radio class="mt-10" :label="1">
                  是
                </el-radio>
                <el-radio class="mt-10" :label="2">
                  否
                </el-radio>
              </el-radio-group>
              <el-input
                placeholder="专职财务几位"
                v-show="formData.financialFlag === 1"
                v-number-input
                v-model="formData.financialNum"
              />
            </div>
          </div>
        </div>
        <div class="mt-20">
          <div><span class="color-F56C6C">*</span>申请原因</div>
          <div>
            <el-input
              v-model="formData.applyContent"
              type="textarea"
              placeholder="限500个字以内"
              maxlength="500"
              show-word-limit
            />
          </div>
        </div>
      </el-card>
      <div class="bootom-button">
        <el-button
          type="primary"
          size="small"
          @click="submit"
          :disabled="submitLoading"
          v-loading="submitLoading"
        >
          提交申请
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { CloudJindie, V2Portal } from '../../apis'
import CryptoJS from 'crypto-js'
export default {
  name: 'KingdeeAudit',
  data () {
    return {
      formData: {},
      loading: false,
      status: 0, // 0表示不可见，1表示可见
      submitLoading: false,
      uuId: ''
    }
  },
  methods: {
    /**
       * 通过8码获取名称
       */
    getMemberName () {
      this.$http.get(CloudJindie.selectMemberBaseBySellerCode, { sellerCode: this.formData.sellerCode }).then(res => {
        if (res.data.success) {
          this.formData.memberName = res.data.data.memberName || []
        } else {
          this.$alert('该88码没有查询得客户名称，请核对', '提示')
        }
      })
    },
    submit () {
      if (!this.formData.sellerCode) {
        this.$message.warning('易理货客户88码不能为空')
        return
      }
      if (!this.formData.memberName) {
        this.$message.warning('易理货客户名称不能为空')
        return
      }
      if (!this.formData.applyName) {
        this.$message.warning('姓名不能为空')
        return
      }
      if (!this.formData.applyPhoneNo) {
        this.$message.warning('手机号不能为空')
        return
      }
      if (!this.formData.financialFlag) {
        this.$message.warning('是否有专职财务人员不能为空')
        return
      }
      if (this.formData.financialFlag === 1 && !this.formData.financialNum) {
        this.$message.warning('专职财务数量不能为空')
        return
      }
      if (!this.formData.applyContent) {
        this.$message.warning('申请原因不能为空')
        return
      }
      this.formData.userId = this.uuId
      this.submitLoading = true
      this.$http.post(CloudJindie.saveKingdeeAccountGroup, this.formData).then(res => {
        if (res.data.success) {
          this.$router.push('/in-the-review?status=2')
        } else {
          this.$message.warning(res.data.errorMsg || '服务异常')
        }
      }).catch(err => {
        console.error(err)
        this.$message.error('服务异常')
      }).finally(() => {
        this.submitLoading = false
      })
    },
    /**
     * aes 解密
     * @param word
     * @returns {string}
     */
    decrypt (val) {
      const p = new Promise((resolve, reject) => {
        // 获取解密所需要的密钥和偏移量
        this.$http.post(CloudJindie.searchFundCenterSwitchConfigByCodes, ['KINGDEE_SECRET_KEY', 'KINGDEE_OFFSET']).then(res => {
          if (res.data.success) {
            // 去掉换行符合，不然无法识别
            const code = decodeURIComponent(val).replace(/\n|\r/g, '')
            const ikey = CryptoJS.enc.Utf8.parse(res.data.data.kingdeeSecretKey)
            const iiv = CryptoJS.enc.Utf8.parse(res.data.data.kingdeeOffset)
            const decrypted = CryptoJS.AES.decrypt(code, ikey, {
              iv: iiv,
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7
            })
            const item = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
            this.uuId = item.data.uid
            if (item && item.data && item.data.accountid) {
              resolve(item.data.accountid)
            } else {
              reject(new Error(('')))
            }
          } else {
            reject(new Error(('')))
          }
        }).catch(err => {
          console.error(err)
          reject(err)
        })
      })
      return p
    },
    /**
     * 获取账套信息
     * @param accountId
     */
    getDate (accountId) {
      this.$http.get(CloudJindie.selectKingdeeAccountGroupByAccountId, { accountId: accountId }).then(res => {
        if (res.data.success) {
          if (!res.data.data.accountId) {
            this.loading = false
            this.$message.warning('账套信息不存在')
            return
          }
          this.formData = res.data.data || {}
          if (this.formData.applyStatus === 1) {
            //  待申请
            this.status = 1
            this.loading = false
          } else if (this.formData.applyStatus === 2) {
            //  待审核
            this.loading = false
            this.$router.push('/in-the-review?status=2')
          } else if (this.formData.applyStatus === 3) {
            //  审核通过
            this.loading = false
            this.$http
              .get(V2Portal.redirectToCC)
              .then(res => {
                if (res.data.success) {
                  window.location.replace(res.data.data)
                } else {
                  this.$message.error(res.data.errorMsg)
                }
              })
              .catch(() => {
                this.loading = false
                this.$message.error('服务器异常')
              })
          } else if (this.formData.applyStatus === 4) {
            //  审核不通过
            this.loading = false
            if (this.$route.query.flag !== '1') {
              // 不知道为啥，需要转码两次才生效
              const applyFailContent = encodeURIComponent(this.formData.applyFailContent)
              this.$router.push(`/in-the-review?status=4&applyFailContent=${encodeURIComponent(applyFailContent)}&accountId=${this.formData.accountId}&uuId=${this.uuId}`)
            }
            this.status = 1
          } else {
            this.loading = false
            this.$message.error('服务器异常')
          }
        } else {
          this.loading = false
          this.$message.warning(res.data.errorMsg || '服务异常')
        }
      }).catch(err => {
        console.error(err)
        this.loading = false
        this.$message.error('服务异常')
      })
    }
  },
  created () {
    this.loading = true
    if (this.$route.query.accountId) {
      this.uuId = this.$route.query.uuId
      this.getDate(this.$route.query.accountId)
    } else {
      this.decrypt(this.$route.query.cipherCode).then(res => {
        this.getDate(res)
      }).catch(err => {
        console.error(err)
        this.loading = false
        this.$message.error('服务异常')
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .kingdee-audit{
    position:absolute;
    bottom:0;
    left: 0;
    background-color:#EBEDF0;
    height:100%;
    width:100%;
    .flex-140{
      flex: 0 0 132px;
      height:50px;
      line-height:60px;
      padding-left: 10px;
    }
    .title-icon{
      height:52px;
      line-height: 52px;
      &::v-deep {
        .el-card__body {
          padding: 0px 24px 5px 24px;
        }
      }
      img{
        width: 120px;
      }
    }
    .empty-data{
      margin:24px;
      padding-top: 116px;
      text-align: center;
      background-color:#fff;
      height:calc(100% - 100px);
      img{
        width:239px;
      }
      .color-909399 {
        color: #909399 !important;
      }
    }
    &_content{
      padding:24px;
      .color-F56C6C{
        color: #F56C6C;
      }
      .color-303133{
        color: #303133;
      }
      .tag{
        margin-left: -20px;
        margin-top: -3px;
        width: 6px;
        height: 16px;
        background: #C0C4CC;
        border-radius: 0px 4px 4px 0px;
      }
      .f-300{
        flex: 0 0 20vw;
      }
      &::v-deep {
        .el-input__inner {
          margin-top: 5px;
          border-top: none !important;
          border-left: none !important;
          border-right: none !important;
          padding-left: 5px !important;
        }
        ::placeholder{
          margin-top: 6px;
         }
        .el-textarea__inner{
          margin-top: 5px;
          border-top: none !important;
          border-left: none !important;
          border-right: none !important;
          line-height:0.5 !important;
          padding-left: 5px !important;
        }
      }
    }
    .bootom-button{
      position: fixed;
      left: 0;
      bottom:0;
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      background: rgba(232, 244, 255, 0.9);
    }
  }
</style>
